/*
* RESPONSIVE CSS
* 
*/

.carousel .slide .legend {
  display: none;
}

.carousel.carousel-slider .control-arrow {
  top: 40%;
  color: black;
  font-size: 26px;
  margin-top: 0;
  padding: 5px;
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
  height: fit-content;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  /* height: 90vh; */
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel .thumbs {
  display: none;
}

.carousel .carousel-status {
  display: none;
}

.main-header input[type="checkbox"],
.main-header .hamburger-lines {
  display: none;
}

.pixie-experince1 {
  display: none;
}

.our-mission-tab1 {
  display: none;
}

.form-submit-button {
  display: flex;
}

.popup-message {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin-left: 50px;
}

.slider-image::before {
  content: "";
  background: rgba(34, 34, 34, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.event-name {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.event-name h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.slider-image:hover::before {
  opacity: 1;
}

.slider-image:hover img {
  transform: scale(1);
}

.slider-image:hover .event-name {
  opacity: 1;
}

@media only screen and (min-width: 993px) and (max-width: 1200) {
  .cards__container {
    width: 993px;
  }

  .next-button {
    position: absolute;
    right: 60px;
    top: 100px;
  }

  .pre-button {
    position: absolute;
    left: 60px;
    top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-logo img {
    width: 175px;
    height: auto;
  }
  .home_h1_h2 {
    top: 38%;
  }
  .button-27 {
    min-height: 30px;
    font-size: 18 px;
  }
  .header_icon_sub_div {
    padding: 3px 10px;
  }

  .main-header {
    padding: 10px 60px;
  }

  ul.footer-quick-links {
    margin-left: 30px;
    margin-top: 40px;
  }

  .contact-info-detail {
    font-size: 14px;
  }

  .footer-contact-info {
    margin-right: 7px;
  }

  .pixie-experince1 {
    display: none;
  }

  .header-menu ul li.contact-us-btn {
    padding: 5px 25px;
  }

  .header-menu ul li {
    padding: 5px 5px;
    margin: 6px 2px;
  }

  header {
    height: 80px;
  }

  .services-footer {
    width: 100%;
    margin-bottom: 40px;
  }

  .service {
    width: 29%;
  }

  .what-we-do-container {
    width: 100%;
    padding: 15px 30px;
  }

  .about-sec-one-img {
    width: 90%;
  }

  .about-sec-one-right-boxes {
    margin-top: 0px;
  }

  .about-sec-one-title1 {
    font-size: 16px;
  }

  .our-vision-tab {
    width: 31%;
    margin-bottom: 20px;
    float: left;
  }

  .our-vision-tabs {
    display: block;
  }

  .why-choose-us-profile-img img {
    width: 100%;
  }

  .contact-detail {
    width: 350px;
    padding: 50px 50px;
  }

  .contact-inputs {
    padding: 40px 30px;
    width: calc(100% - 350px);
  }

  .about-right-content {
    font-size: 14px;
    width: 100%;
  }

  .about-right-title {
    font-size: 25px;
  }

  .work-button button {
    margin-right: 9px;
  }

  .projects-section .project {
    width: 46%;
    float: left;
    padding: 30px 20px;
  }

  .about-left-img img {
    width: 330px;
  }

  .about-img-bottom-text {
    right: -18px;
  }

  .life-at-pixie-small-image {
    left: -70px;
  }

  .life-at-pixie-small-image img {
    width: 60% !important;
  }

  .life-at-pixie-main-image img {
    width: 95%;
  }

  .pixie-exp.exp1 {
    top: -80px;
  }

  .pixie-exp {
    width: 130px;
  }

  .pixie-experience {
    background-size: auto 80%;

    width: 769px;

    margin-bottom: 0px;
  }

  .pixie-exp.exp2 {
    top: 40px;
    left: 236px;
  }

  .pixie-exp.exp3 {
    top: 40px;
    right: 235px;
  }

  .pixie-exp.exp4 {
    top: 40px;
    right: 64px;
  }

  .our-mission-tabs {
    height: 285px;
    width: 100%;
    margin: 30px auto 50px auto;
  }

  .mission-content.mission-content-1 {
    top: 130px;
    left: 10px;
  }

  .mission-content {
    position: absolute;
    width: 125px;
  }

  .mission-content.mission-content-3 {
    left: 360px;
  }

  .mission-content.mission-content-4 {
    right: 60px;
    bottom: 25px;
  }

  .mission-content.mission-content-5 {
    right: 57px;
    top: 70px;
  }

  .lower {
    bottom: 5px;
  }

  .cards__container {
    width: 770px;
    position: relative;
    margin: 0 auto;
  }

  .box {
    width: 25rem;
  }

  .blog {
    width: 46%;
  }

  .pre-button {
    left: 20px;
  }

  .next-button {
    right: 20px;
  }

  .box:nth-child(1) {
    left: 10%;
  }

  .box:nth-child(2) {
    left: 15%;
  }

  .box:nth-child(3) {
    left: 22%;
  }

  .box:nth-child(4) {
    right: 19%;
  }

  .box:nth-child(5) {
    right: 14%;
  }

  .home-header-tech-icon.tech-icon1 {
    right: 23%;
    top: 40%;
  }

  .home-header-tech-icon.tech-icon2 {
    left: 15%;
    top: 72%;
  }

  .home-header-tech-icon.tech-icon3 {
    left: 44%;
    top: 32%;
  }

  .home-header-tech-icon.tech-icon4 {
    left: 20%;
    top: 40%;
  }

  .home-header-tech-icon.tech-icon5 {
    right: 15%;
    top: 72%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .service_row_ {
    margin: 10px 20px 30px 20px;
    justify-content: center;
}
.home_h1_h2 {
  top: 27%;
}
.button-27 {
  min-height: 35px;
  font-size: 18 px;
}
.contact-info-detail {
  margin-top: 20px;
  font-size: 16px;
}
.footer-title {
  font-size: 20px;
  color: var(--white);
  padding-top: 20px;
  text-align: center;
}
  .service_ {
    width: 45.33% !important;
  }

  .home_img_round_main {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 80%;
  }

  .home__logo_text {
    width: 80%;
  }

  .header-menu ul li {
    float: none !important;
  }

  .header_icon_name {
    color: #fff;
  }

  .work-button-container {
    margin: 30px 0px 0px 0px;
    overflow-x: scroll;
  }

  .buttons_mobileview {
    overflow-y: scroll;
    white-space: nowrap;
  }

  .home_img_ {
    width: 100px;
    height: auto;
  }

  .home_img_round_text {
    font-size: 20px;
    text-align: center;
    line-height: 1.5rem;
  }

  nav.col.nav_col_main {
    display: block;
    margin: 20px 0px 0px 20px;
    padding: 20px 0px;
  }

  .header_icon_sub_div {
    margin: 18px 0;
  }

  .main-header {
    padding: 0px;
  }

  .main-logo {
    padding-top: 16px;
  }

  .main-header input[type="checkbox"],
  .main-header .hamburger-lines {
    display: block;
  }

  .main-header input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    right: 10px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .main-header .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    right: 10px;

    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .main-header .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--primary);
  }

  .main-header .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .main-header .hamburger-lines .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .main-header .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .main-header .header-menu {
    padding-top: 0px;
    background: linear-gradient(45deg, black, transparent);
    backdrop-filter: blur(15px);
    height: 100%;
    width: calc(100% - 50%);
    position: fixed;
    transform: translate(150%);
    display: flex;
    flex-direction: column;
    right: 0%;
    padding-right: 40px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
    top: -30px;
  }

  .main-header .header-menu li {
    font-size: 1rem;
    font-weight: 500;
  }

  .main-header input[type="checkbox"]:checked~.header-menu {
    transform: translateX(0);
  }

  .main-header input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
    background-color: var(--primary);
  }

  .main-header input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .main-header input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
    background-color: var(--primary);
  }

  .about-right-title {
    text-align: center;
  }

  .about-right-content {
    width: 100%;
    text-align: justify;
  }

  .service {
    width: 44%;
    margin: 2% 3%;
  }

  .service-title {
    margin-top: 20px;
  }

  header {
    height: 80px;
  }

  .about-right-button {
    margin-bottom: 30px;
  }

  .work-button button {
    margin-bottom: 8px;
  }

  .section-title {
    margin: 20px auto;
  }

  .blog {
    float: left;
    width: 46%;
  }

  .pre-button {
    left: 30px;
  }

  .contact-detail {
    float: none;
    width: 100%;
    padding: 40px;
    padding-bottom: 10px;
  }

  .projects-section .project {
    width: 46%;
    float: left;
    padding: 40px 20px;
    margin: 2%;
  }

  .footer-contact-info {
    width: 21px;
  }



  .address {
    margin-top: 3px;
  }

  .social-media-icons {
    margin: 20px auto;
  }

  .contact-inputs {
    float: none;
    width: 100%;
  }

  .our-mission-tabs {
    height: 210px;
    width: 100%;
    margin: 30px auto 40px auto;
  }

  .mission-content {
    position: absolute;
    width: 90px;
  }

  .mission-content.mission-content-1 {
    top: 90px;
  }

  .mission-content.mission-content-2 {
    top: 50px;
    left: 145px;
  }

  .mission-content.mission-content-3 {
    left: 280px;
    top: -30px;
  }

  .mission-content.mission-content-4 {
    right: 70px;
    bottom: -30px;
  }

  .mission-content.mission-content-5 {
    right: 40px;
    top: 40px;
  }

  .our-vision-tabs {
    float: none;

  }

  .our-vision-tab {
    width: 48%;
    margin-top: 37px;
    float: left;
  }

  .our-vision-tabs {
    display: block;
  }

  .services-footer {
    width: 100%;
  }

  .what-we-do-container {
    margin-bottom: 10px;
  }

  .about-sec-one-img {
    width: 100%;
    margin-bottom: 10px;
  }

  .about-sec-one-text {
    width: 100%;
    text-align: justify;
  }

  .about-sec-one-right-boxes {
    margin-top: 0px;
  }

  .box {
    width: 20rem;
  }

  .box:nth-child(1) {
    left: 8%;
  }

  .box:nth-child(2) {
    left: 14%;
  }

  .box:nth-child(3) {
    left: 23%;
  }

  .box:nth-child(4) {
    right: 14%;
  }

  .box:nth-child(5) {
    right: 9%;
  }

  .card1 {
    height: 290px;
  }

  .next-button {
    right: 30px;
  }

  section.section-secondary-bg {
    padding: 10px 0 30px 0;
  }

  .experience-text {
    font-size: 10px;
  }

  .pixie-exp {
    width: 110px;
    position: absolute;
  }

  .pixie-experience {
    background-size: auto 65%;

    width: 600px;
    margin: 0 auto;
    height: 200px;
  }

  .pixie-exp.exp1 {
    top: 46px;
    left: 43px;
  }

  .pixie-exp.exp2 {
    top: 50px;
    left: 180px;
  }

  .pixie-exp.exp3 {
    top: 50px;
    right: 180px;
  }

  .pixie-exp.exp4 {
    top: 50px;
    right: 42px;
  }

  .pixie-experince1 {
    display: none;
  }

  .header-menu .nav-link {
    color: white;
  }

  .header-menu ul li.contact-us-btn {
    text-align: center;
    margin-top: 250px;
  }

  .cards__container {
    width: 600px;
    position: relative;
    margin: 0 auto;
  }

  .lower {
    position: relative;
    /* bottom: -24%; */
    bottom: 0%;
  }

  .home-header-tech-icon.tech-icon1 {
    right: 10%;
    top: 40%;
  }

  .home-header-tech-icon.tech-icon2 {
    left: 5%;
    top: 72%;
  }

  .home-header-tech-icon.tech-icon3 {
    left: 43%;
    top: 32%;
  }

  .home-header-tech-icon.tech-icon4 {
    left: 10%;
    top: 40%;
  }

  .home-header-tech-icon.tech-icon5 {
    right: 5%;
    top: 72%;
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  .service_ {
    width: 45.33% !important;
    margin-bottom: 20px;
  }
  .home_h1_h2 {
    top: 25%;
  }
  .button-27 {
    min-height: 30px;
    font-size: 18 px;
  }

  .footer-title {
    font-size: 20px;
    color: var(--white);
    padding-top: 20px;
    text-align: center;
  }
  .contact-info-detail {
    margin-top: 20px;
    font-size: 16px;
}
  .service_row_ {
    margin: 10px 20px 30px 20px;
    justify-content: center;
}
}

@media only screen and (min-width: 200px) and (max-width: 500px) {
  .footer-title {
    font-size: 20px;
    color: var(--white);
    padding-top: 20px;
    text-align: center;
  }
  .home_h1_h2 {
    top: 21%;
  }
  .button-27 {
    min-height: 30px;
    font-size: 18 px;
  }
  .contact-info-detail {
    margin-top: 20px;
    font-size: 16px;
}
  .service_ {
    width: 95% !important;
    margin-bottom: 25px;
  }
  .service_row_ {
    margin: 10px 20px 30px 20px;
    justify-content: center;
}
  .home_img_round_main {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 80%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .cards__container {
    width: 400px;
  }

  .pixie-experince1 {
    display: block;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {

  /* home page start  */
  .pixie-experince1 {
    display: block;
  }

  .header_icon_name {
    color: #fff;
  }

  .buttons_mobileview {
    overflow-y: scroll;
    white-space: nowrap;
  }

  .col-lg-6.col-md-6.col-sm-12.footer_logo_300 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .work-button-container {
    margin: 30px 0px 0px 0px;
    overflow-x: scroll;
  }

  .home_img_ {
    width: 100px;
    height: auto;
  }

  .home_img_round_text {
    font-size: 20px;
    text-align: center;
    line-height: 1.5rem;
  }

  .header_icon_sub_div {
    margin: 18px 0;
  }

  .about-left-img img {
    width: 250px;
  }

  .about-img-bottom-text {
    width: 150px;
    height: 150px;
    right: 0px;
  }

  .years {
    margin-top: 15px;
    font-size: 30px;
  }

  .experience {
    font-size: 18px;
  }

  .about-right-title {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .about-right-content {
    font-size: 16px;
    padding: 0 10px;
  }

  .service {
    width: 75%;
    margin: 2% 12%;
  }

  .our-work-content {
    text-align: center;
  }

  .our-work-header {
    text-align: center;
  }

  .projects-section .project {
    width: 90%;

    padding: 30px 30px;
    margin: 5%;
  }

  .header-menu .nav-link {
    color: white;
  }

  .blog {
    float: none;
    width: 96%;
    margin: 3%;
  }

  .contact-detail {
    float: none;
    width: 100%;
  }

  .contact-inputs {
    padding: 40px 50px;
    float: none;
    width: 100%;
  }

  .contact-inputs .inputs input {
    float: none;
    width: 100%;
  }

  .about-right-button {
    margin-bottom: 30px;
  }

  section.section-secondary-bg {
    padding: 10px 0 20px 0;
    margin: 10px 0;
  }

  .review {
    padding: 20px 20px;
  }

  .section-title {
    font-size: 20px;

    margin: 30px auto;
  }

  .box:nth-child(1) {
    left: 0%;
    opacity: 0;
  }

  .box:nth-child(2) {
    left: 0%;
    opacity: 0;
  }

  .box:nth-child(4) {
    left: 0%;
    opacity: 0;
  }

  .box:nth-child(5) {
    left: 0%;
    opacity: 0;
  }

  .box:nth-child(3) {
    left: 5.5%;
  }

  .box {
    width: 13rem;
  }

  .card1 {
    font-size: 12px;
    height: 250px;
  }

  .lower {
    bottom: 0%;
  }

  .pre-button {
    left: 5px;
  }

  .next-button {
    right: 5px;
  }

  .ourtestimonial {
    height: 325px;
  }

  .cards__container {
    width: 320px;
  }

  .box:nth-child(3) {
    left: 16.5%;
  }

  /* home page end  */

  /* hemburger menu  */

  .header-menu ul li {
    float: none !important;
  }

  .main-header {
    padding: 0px;
  }

  .main-logo {
    padding-top: 16px;
  }

  .main-header input[type="checkbox"],
  .main-header .hamburger-lines {
    display: block;
  }

  .main-header input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    right: 10px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .main-header .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    right: 10px;

    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .main-header .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--primary);
  }

  .main-header .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .main-header .hamburger-lines .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .main-header .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  nav.col.nav_col_main {
    display: block;
    margin: 20px 0px 0px 20px;
    padding: 20px 0px;
  }

  .main-header .header-menu {
    padding-top: 0px;
    background: linear-gradient(rgba(0,0,0,.9) , rgba(0,0,0,0.9));
    backdrop-filter: blur(15px);
    height: 100%;
    width: calc(100% - 50%);
    position: fixed;
    transform: translate(150%);
    display: flex;
    flex-direction: column;
    right: 0%;
    padding-right: 40px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
    top: -30px;
  }


  .main-header .header-menu li {
    font-size: 1rem;
    font-weight: 500;
  }

  .main-header input[type="checkbox"]:checked~.header-menu {
    transform: translateX(0);
  }

  .main-header input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
    background-color: var(--primary);
  }

  .main-header input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .main-header input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
    background-color: var(--primary);
  }

  .work-button button {
    margin-right: 24px;
    margin-bottom: 10px;
  }

  .contact-container {
    background-size: 200% auto;
    background-position: top center;
  }

  /* complete hamburger menu code */

  /* about page  */

  .our-vision-title {
    font-size: 18px;
    width: 100%;
  }

  .our-vision-tabs {
    display: block;
  }

  .our-vision-tab {
    margin-right: 0px;
    width: 100%;
    margin-top: 13%;
    margin-left: 0px;
    margin-bottom: 0;
  }

  .why-choose-us-profile-img img {
    margin-bottom: 25px;
  }

  .about-sec-one-img {
    width: 100%;
  }

  .about-sec-one-img img {
    margin-bottom: 10px;
  }

  .about-sec-one-text {
    font-size: 12px;
    width: 100%;
    text-align: justify;
  }

  .about-sec-one-right-boxes {
    margin-top: 30px;
  }

  .our-mission-tab1 {
    display: block;
    position: relative;
  }

  .our-mission-tabs {
    display: none;
  }

  .circle1 {
    width: 148px;
    height: 148px;
    left: 275px;
    top: 3173px;
    background: #5995ff;
    border: 5px solid #5995ff;
    border-radius: 50%;
    margin-left: 10px;
    overflow: hidden;
  }

  .circle_div {
    width: 200px;
    left: 35%;
    margin-top: -70px;
    position: absolute;
  }

  .circle1_p1 {
    font-size: 16px;
    font-weight: 600;
  }

  .circle1_p2 {
    font-size: 13px;
    margin-top: -10px;
  }

  .rectangle {
    box-sizing: border-box;
    position: relative;
    width: 60px;
    height: 60px;
    border-width: 0px 2px 0px 0px;
    border-style: dashed;
    border-color: #bccde4;
    right: 16px;
  }

  .circle {
    width: 65px;
    height: 65px;
    background: #5995ff;
    border: 5px solid #5995ff;
    border-radius: 50%;
    margin-left: 10px;
    overflow: hidden;
  }

  .circle-number {
    font-size: 50px;
    font-family: "nunitosemibold";
    color: var(--secondarylight);
    opacity: 0.2;
  }

  .circle_title_div {
    margin-top: -40px;
    margin-left: 5px;
  }

  /* service page */

  .services-footer {
    width: 100%;
  }

  .services-footer-numbers {
    /* float: left; */
    width: 90%;
    float: none;
    border-right: none;
    border-bottom: 1px solid var(--primarylight);
    padding-bottom: 5px;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .service-footer-number {
    font-size: 16px;
  }

  .what-we-do-container {
    margin-bottom: 10px;
    width: 82%;
    margin: 20px 9%;
  }

  .life-at-pixie-small-image {
    left: -45px;
    right: auto;
  }

  .life-at-pixie-small-image img {
    width: 37%;
  }

  .lifeatpixie-header-points {
    display: block;
  }

  .lifeatpixie-header-content {
    width: 100%;
    /* width: 450px; */
    margin: 10px 0 30px 0;
  }

  .pixie-experience {
    display: none;
  }

  .pixie-experience1 {
    display: block;
  }

  .pixie-exp.exp1 {
    background-color: #fff;
    padding: 10px;
    /* margin-left: 70px; */
    border-left: 10px solid #02c2bc;
    border-top: 10px solid #02c2bc;
    border-bottom: 10px solid #02c2bc;
    border-radius: 18px;
    border-right: 10px solid #fff;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .pixie-exp.exp2 {
    background-color: #fff;
    padding: 10px;
    /* margin-left: 70px; */
    border-left: 10px solid #ffffff;
    border-top: 10px solid #00bce8;
    border-bottom: 10px solid #00bce8;
    border-radius: 18px;
    border-right: 10px solid #00bce8;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .pixie-exp.exp3 {
    background-color: #fff;
    padding: 10px;
    /* margin-left: 70px; */
    border-left: 10px solid #ffc160;
    border-top: 10px solid #ffc160;
    border-bottom: 10px solid #ffc160;
    border-radius: 18px;
    border-right: 10px solid #fff;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .pixie-exp.exp4 {
    background-color: #fff;
    padding: 10px;
    /* margin-left: 70px; */
    border-left: 10px solid #fff;
    border-top: 10px solid #ef2f3a;
    border-bottom: 10px solid #ef2f3a;
    border-radius: 18px;
    border-right: 10px solid #ef2f3a;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .pixie-exp {
    width: 50%;
    position: inherit;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .header-menu ul li.contact-us-btn {
    text-align: center;
    margin-top: 250px;
  }

  .home-header-tech-icon {
    width: 60px;
  }

  .home-header-tech-icon.tech-icon1 {
    right: 5%;
    top: 50%;
  }

  .home-header-tech-icon.tech-icon2 {
    left: 0%;
    top: 72%;
  }

  .home-header-tech-icon.tech-icon3 {
    left: 39%;
    top: 42%;
  }

  .home-header-tech-icon.tech-icon4 {
    left: 5%;
    top: 50%;
  }

  .home-header-tech-icon.tech-icon5 {
    right: 0%;
    top: 72%;
  }

  img.home-header-center-img {
    width: 255px;
    bottom: 30px;
  }
}